<template>
  <v-text-field v-model="display" label="カード名義人" hide-details="auto" outlined dense :error-messages="errorMessages" @input="cardNameFormatter">
  </v-text-field>
</template>

<script>
import {
  isEmpty,
} from '@/utils'

export default {
  props: {
    value: {
      // eslint-disable-next-line no-bitwise, vue/require-prop-type-constructor
      type: String | Number,
      required: true,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    display: '',
  }),
  watch: {
    value: {
      handler(val) {
        this.cardNameFormatter(val)
      },
      immediate: true,
    },
  },

  methods: {
    cardNameFormatter(val) {
      if (isEmpty(val)) {
        this.$emit('input', '')
        this.display = ''

        return
      }
      const format = val.replace(/[^a-zA-Z ]/g, '').toUpperCase()
      this.$nextTick(() => {
        this.display = format
      })
      this.$emit('input', format)
    },
  },
}
</script>

<style>

</style>
