<template>
  <v-text-field v-model="display" outlined dense label="CVV" :error-messages="errorMessages" @input="handleInput"></v-text-field>
</template>

<script>
import {
  isEmpty,
} from '@/utils'

export default {
  props: {
    value: {
      // eslint-disable-next-line no-bitwise, vue/require-prop-type-constructor
      type: String | Number,
      required: true,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    display: '',
  }),
  watch: {
    value: {
      handler(val) {
        this.handleInput(val)
      },
      immediate: true,
    },
  },

  methods: {
    handleInput(val) {
      if (isEmpty(val)) {
        this.$emit('input', '')
        this.display = ''

        return
      }
      const noWhiteSpace = val.replace(/[^\d]/g, '').slice(0, 4)
      this.$nextTick(() => {
        this.display = noWhiteSpace
      })
      this.$emit('input', noWhiteSpace)
    },
  },
}
</script>

<style>

</style>
