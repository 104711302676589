<template>
  <v-text-field v-model="display" label="カード番号" outlined dense :error-messages="errorMessages" hide-details="auto" @input="cardNoFormatter">
  </v-text-field>
</template>

<script>
import {
  isEmpty,
} from '@/utils'

export default {
  props: {
    value: {
      // eslint-disable-next-line no-bitwise, vue/require-prop-type-constructor
      type: String | Number,
      required: true,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    display: '',
  }),
  watch: {
    value: {
      handler(val) {
        this.cardNoFormatter(val)
      },
      immediate: true,
    },
  },
  methods: {
    cardNoFormatter(val) {
      if (isEmpty(val)) {
        this.$emit('input', '')
        this.display = ''

        return
      }
      const type = this.getCardType(val)
      this.$emit('cardType', type)
      switch (type) {
        case 'amex':
        case 'diners':
          this.handleAmexOrDinersCard(val)
          break
        default:
          this.handleOtherCard(val)
          break
      }
    },
    handleAmexOrDinersCard(number) {
      const noWhiteSpace = number.replace(/[^\d]/g, '').slice(0, 16)
      const format = noWhiteSpace.replace(/(\d{4})(\d{6})?(\d{5})?/, '$1 $2 $3').trim()
      this.$nextTick(() => {
        this.display = format
      })
      this.$emit('input', noWhiteSpace)
    },
    handleOtherCard(number) {
      const noWhiteSpace = number.replace(/[^\d]/g, '').slice(0, 16)
      const format = noWhiteSpace.replace(/([\d*]{4})/g, '$1 ').trim()
      this.$nextTick(() => {
        this.display = format
      })
      this.$emit('input', noWhiteSpace)
    },
    getCardType(number) {
      let re = new RegExp('^4')
      if (number.match(re) != null) return 'visa'

      re = new RegExp('^(34|37)')
      if (number.match(re) != null) return 'amex'

      re = new RegExp('^(3528|3589)')
      if (number.match(re) != null) return 'jcb'

      re = new RegExp('^(36|38|39|30[0-5])')
      if (number.match(re) != null) return 'diners'

      re = new RegExp('^5[1-5]')
      if (number.match(re) != null) return 'master'

      return 'visa' // default type
    },
  },

}
</script>

<style>

</style>
