<template>
  <v-form>
    <div style="font-size: 18px">
      <strong class="d-flex justify-center">カード情報</strong>
    </div>
    <v-row v-show="errMsg" class="ml-6 mb-3 ma-3">
      <span style="color: red">* {{ errMsg }} </span>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>カード番号</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
          <p style="color: gray" class="subtitle-2">半角数字</p>
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <CardNumberInput v-model="$v.info.cardNo.$model" :error-messages="cardNoError"></CardNumberInput>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span class="text-red">カード名義人</span>

          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
          <p style="color: gray" class="subtitle-2">カードに記載のローマ字の大文字</p>
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <CardNameInput v-model="$v.info.owner.$model" :error-messages="ownerError"></CardNameInput>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>有効期限・CVV</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(date)"
                readonly
                outlined
                dense
                placeholder="有効期限(月/年)"
                hide-details="auto"
                v-bind="attrs"
                :error-messages="cardExpiresError"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" scrollable locale="ja-JP">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> 取消 </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date)"> 確認 </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <CVV v-model="$v.info.CVV.$model" :error-messages="CVVError"></CVV>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-form>
</template>
<script>
import { ref } from 'vue'
import { required } from 'vuelidate/lib/validators'

// import {
//   isEmpty,
// } from '@/utils'

import CardNameInput from './CardNameInput.vue'
import CardNumberInput from './CardNumberInput.vue'
import CVV from './CVV.vue'

export default {
  components: {
    CardNameInput,
    CardNumberInput,
    CVV,
  },

  props: {
    isCardOpen: {
      type: Boolean,
      required: true,
    },
    cardDetail: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  setup() {
    const submitLoading = ref(false)
    const info = ref({
      cardNo: '',
      cardExpires: '',
      CVV: '',
      owner: '',
      cardImg: '',
    })
    const date = ref('')
    const modal = ref(false)
    const errMsg = ref('')

    return {
      info,
      submitLoading,
      date,
      modal,
      errMsg,
    }
  },
  data: () => ({}),
  computed: {
    cardNoError() {
      const errors = []
      if (!this.$v.info.cardNo.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.info.cardNo.required && errors.push('必須項目')

      return errors
    },
    ownerError() {
      const errors = []
      if (!this.$v.info.owner.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.info.owner.required && errors.push('必須項目')

      return errors
    },
    cardExpiresError() {
      const errors = []

      if (!this.$v.info.cardExpires.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.info.cardExpires.required && errors.push('必須項目')

      return errors
    },
    CVVError() {
      const errors = []

      if (!this.$v.info.CVV.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.info.CVV.required && errors.push('必須項目')

      return errors
    },
  },
  validations: {
    info: {
      cardNo: {
        required,
      },
      cardExpires: {
        required,
      },
      CVV: {
        required,
      },
      owner: {
        required,
      },
    },
  },
  watch: {
    isCardOpen() {
      // if (this.action === 'create') {
      //   console.log('create')
      this.clearCard()

      // }

      //  else {
      //   console.log('edit')
      //   this.info = {
      //     cardNo: this.cardDetail.cardNo,
      //     cardExpires: this.cardDetail.cardExpires,
      //     CVV: this.cardDetail.CVV,
      //     owner: this.cardDetail.owner,
      //   }
      // }
    },
  },

  created() {
    this.$root.$on('gmo_multipayment_result', this.handleMultipaymentResult)
  },
  beforeDestroy() {
    this.$root.$off('gmo_multipayment_result', this.handleMultipaymentResult)
  },
  mounted() {
    this.clearCard()
  },
  methods: {
    resetValidation() {
      this.$v.$reset()
    },
    clearCard() {
      this.info = {
        cardNo: '',
        cardExpires: '',
        CVV: '',
        owner: '',
      }
      this.date = ''
      this.errMsg = ''
      this.$v.$reset()
    },
    formatDate(date) {
      if (!date) return null

      const [year, month] = date.split('-')
      this.info.cardExpires = `${year.substring(2)}${month}`

      return `${month}/${year.substring(2)}`
    },

    // https://www.cnblogs.com/surfer/p/9685339.html
    // 回调函数多次被执行
    // 可能是vue-cli hot-reload的问题
    handleMultipaymentResult(res) {
      if (res.resultCode === '000') {
        this.$emit('sendToken', res.tokenObject)
      } else {
        this.$emit('sendToken', null)

        this.errMsg = 'カード登録に失敗しました。'
        console.error('カード登録に失敗しました。', res)
      }
    },
    submitCard() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$emit('sendToken', null)

        return
      }

      this.errMsg = ''
      const cardObject = {
        cardno: this.info.cardNo,
        expire: this.info.cardExpires.replace('/', ''),
        securitycode: this.info.CVV,
        holdername: this.info.owner,
      }
      console.log('cardObject', cardObject)
      // eslint-disable-next-line no-undef
      Multipayment.getToken(cardObject, 'GMO_Multipayment_result')
    },
  },
}
</script>
